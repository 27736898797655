export default class UserDeviceViewModel {

    public client: string = '';
    public group: string = '';
    public email: string = '';
    public ipAddress: string = '';
    public browserName: string = '';
    public browserVersion: string = '';
    public osName: string = '';
    public osVersion: string = '';
    public platformType: string = '';

}