import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { unregister } from './registerServiceWorker'; import { runWithAdal } from 'react-adal';
import { authContext } from './adalConfig';

const DO_NOT_LOGIN = false;
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');


runWithAdal(authContext, () => {

    ReactDOM.render(
        <BrowserRouter basename={baseUrl!}>
            <App />
        </BrowserRouter>,
        rootElement);

    unregister();

}, DO_NOT_LOGIN);


