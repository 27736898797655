import { AuthenticationContext, adalFetch, withAdalLogin, adalGetToken } from 'react-adal';

export const adalConfig = {
    tenant: '245ed2ed-ed88-4f76-bcb5-6ec006bb68ed',
    clientId: '7dd42f3c-0064-44b4-9ec4-06a636fd42c1',
    endpoints: {
        api: '7dd42f3c-0064-44b4-9ec4-06a636fd42c1',
        powerbi: "https://analysis.windows.net/powerbi/api",
        graph: 'https://graph.microsoft.com'
    },
    postLogoutRedirectUri: window.location.origin,
    redirectUri: window.location.origin,
    cacheLocation: 'sessionStorage'
};

export const authContext = new AuthenticationContext(adalConfig);

export const adalApiFetch = (fetch, url, options) => {
    options = options || {};

    if (!options.headers) {
        options.headers = { 'Authorization': 'bearer ' + getToken() };
    }
    else {
        options.headers.Authorization  = 'bearer ' + getToken();
    }

    return adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);
};

export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.api);

export const adalGraphApiFetch = (fetch, url, options) => {
    options = options || {};

    if (!options.headers) {
        options.headers = { 'Authorization': 'bearer ' + getToken() };
    }
    else {
        options.headers.Authorization = 'bearer ' + getToken();
    }

    return adalFetch(authContext, adalConfig.endpoints.graph, fetch, url, options);
};

export const withAdalGraphApi = withAdalLogin(authContext, adalConfig.endpoints.graph);


export const getToken = () => {
    return authContext.getCachedToken(authContext.config.clientId);
};

export const getBIToken = () => {
    return adalGetToken(authContext, adalConfig.endpoints.powerbi);
};
