import * as React from 'react';
import Layout from './components/Layout';

import {  Switch } from 'react-router';


export default class App extends React.Component<any, any> {
    static displayName = App.name;
    public render() {
        return (
            <Layout>
                <Switch>
                    {/*<Route path='/Submitted/' component={Submitted} />*/}
                    {/*<Route path='/Sales/' component={SalesToolContainer} />*/}
                    {/*<Redirect exact from='/Test' to="/Sales/?customerID=000&username=Test%20Agent&PhoneNumber=01234567891&mpan=123123123&mprn=1234567890123&callreference=NotInCall&reportcode1=Standard" />*/}


                </Switch>
            </Layout>
        );
    }
}
