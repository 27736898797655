import * as React from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { AccountSection } from './Account/AccountSection';
import ClientConfigurationViewModel from './ViewModels/ClientConfigurationViewModel';
import ByodDisplay from './Groups/ByodDisplay';
import { adalApiFetch } from '../adalConfig';
import Grow from '@material-ui/core/Grow/Grow';


const styles = (theme: Theme) => createStyles({
    root: {
        flexGrow: 1
    },
    pad: {
        padding: theme.spacing(1)
    },
    title: {
        marginRight: theme.spacing(2)
    },
    appBarTitle: {
        marginLeft: theme.spacing(1),
        color: theme.palette.primary.contrastText,
    },
    input: {},
    dropdown: {}
});

interface INavMenuProps extends WithStyles<typeof styles> {
}

interface INavMenuState {
    validUser: boolean;
    openSupportModal: boolean;

    clientConfig: ClientConfigurationViewModel[];

    loadedData: boolean;

    adminPrivileges: boolean;
    agentNumber: string;
    client: string;
    channel: string;
    role: string;

    agentName: string;

    enteredArray: boolean[];
    group: string[];
}

export class NavMenu extends React.Component<INavMenuProps, INavMenuState> {
    constructor(props: INavMenuProps) {
        super(props);

        this.state = {
            validUser: false,
            clientConfig: [],
            loadedData: true,
            adminPrivileges: false,
            agentNumber: '',
            channel: '',
            client: '',
            role: '',
            agentName: '',
            enteredArray: [false, false, false, false],
            openSupportModal: false,
            group: []
        };
    }

    public componentDidMount() {
        this.validateUser();
        this.checkAccess();
    }

    public render() {
        const classes = this.props.classes;
        const ClientLogo = this.backgroundLogo();

        return (
            <div className={this.props.classes.root}>
                {this.state.loadedData
                    ?
                    <React.Fragment>
                        <AppBar position="fixed" color="secondary">
                            <Toolbar>
                                <Grid container justify="space-between" >
                                    <Grid item>
                                        <Grid container alignItems="center">
                                            <Grid item>
                                                <img src="/iQLogo.png" style={{ marginTop: "7px" }} width="40" height="40" alt="Logo" />
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="h6" style={{ marginTop: "5px" }} className={classes.appBarTitle} noWrap>ROAD Portal</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container alignItems="center">
                                            {this.state.loadedData &&
                                                <AccountSection
                                                    setName={this.setName}
                                                    adminPrivileges={this.state.adminPrivileges}
                                                    role={this.state.role}
                                                    agentNumber={this.state.agentNumber}
                                                    channel={this.state.channel}
                                                    classes={classes}
                                                    client={this.state.client}
                                                    dropdownChange={this.dropdownChange}
                                                />}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Toolbar >
                        </AppBar >
                        <Toolbar />

                        <Container maxWidth="lg" className={this.props.classes.pad}>
                            {this.state.group.length === 0 ?
                                <React.Fragment>
                                    <Grow
                                        in={true}
                                        style={{ transformOrigin: '0 0 0' }}
                                        timeout={1000}
                                        onEntered={this.setEntered(0)}
                                    >
                                        <Typography variant="h3" style={{ marginTop: "5px" }} color="secondary" noWrap>Hello, {this.state.agentName}!</Typography>
                                    </Grow>
                                    <br />
                                    <Grow
                                        in={this.state.enteredArray[0]}
                                        style={{ transformOrigin: '0 0 0' }}
                                        timeout={1000}
                                        onEntered={this.setEntered(1)}
                                    >
                                        <Grid>
                                            <Typography variant="h4" style={{ marginTop: "5px" }} color="secondary" noWrap>There is nothing configured, please contact IT services below</Typography>
                                        </Grid>
                                    </Grow>
                                    <br />

                                    {ClientLogo}
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <Grow
                                        in={this.state.enteredArray[1]}
                                        style={{ transformOrigin: '0 0 0' }}
                                        timeout={1000}
                                    >
                                        <Typography variant="h6" color="secondary" style={{ margin: '10px' }}>
                                            For further support, please contact us at <a style={{ color: '#573D90' }} href="mailto:ITServicesTeam@resqcs.co.uk">ITServicesTeam@resqcs.co.uk</a> or on 01482 481 200
                                        </Typography>
                                    </Grow>
                                </React.Fragment >
                                :
                                <ByodDisplay
                                    setEntered={this.setEntered}
                                    openExternalLink={this.openExternalLink}
                                    validateUser={this.validateUser}
                                    backgroundLogo={this.backgroundLogo}
                                    agentName={this.state.agentName}
                                    agentNumber={this.state.agentNumber}
                                    enteredArray={this.state.enteredArray}
                                    clientConfig={this.state.clientConfig}
                                    group={this.state.group}
                                />
                            }
                        </Container>
                    </React.Fragment>
                    :
                    <Typography variant="h5" color="secondary" style={{ margin: '10px' }}>You are not authorised to view this page.</Typography>
                }
            </div >
        );
    }

    // Determines which background icon should be displayed, based on the current URL.
    private backgroundLogo = () => {
        return <React.Fragment>
            <div style={{
                position: 'absolute',
                right: '0',
                bottom: '0',
                filter: 'grayscale(100%)', opacity: 0.15,

            }}>
                <img src={"/" + this.state.clientConfig[0]?.clientImage} draggable={false} width="300" height="100%" alt="Logo" />
            </div>
        </React.Fragment >;
    }

    // private toggleSupportModal = () => {
    //     this.setState({ openSupportModal: !this.state.openSupportModal });
    // }

    private validateUser = () => {
        adalApiFetch(fetch, `api/User/ValidateUser`)
            .then((response: Response) => response.json())
            .then((data: any) => {

                console.log(data)
                this.setState({ validUser: data.client !== null, client: data.client, adminPrivileges: data.admin, loadedData: true });


            })
            .catch((error: Error) => {
                console.log(error);
            });
    }

    private checkAccess = () => {
        adalApiFetch(fetch, `api/User/checkAccess`)
            .then((response: Response) => response.json())
            .then((data: any) => {
                this.setState({ group: data });
                console.log(data)
                this.getClientConfiguration();

            })
            .catch((error: Error) => {
                console.log(error);
            });
    }

    private getClientConfiguration = () => {
        adalApiFetch(fetch, `api/User/GetClientConfiguration?group=${this.state.group.length === 2 ? 'Dual' : this.state.group[0]}`)
            .then((response: Response) => response.json())
            .then((data: any) => {
                this.setState({ clientConfig: data });
            })
            .catch((error: Error) => {
                console.log(error);
            });
    }

    private setEntered = (index) => () => {
        const array = this.state.enteredArray;
        array[index] = true;
        this.setState({ enteredArray: array });

    }

    private openExternalLink = (link: string) => () => {
        window.open(link, "_blank");
    }

    private setKeyValue = (key: string, value: string) => {
        this.setState({ [key]: value } as any);
    }


    private setName = (name: string) => {
        this.setState({ agentName: name });
    }

    private dropdownChange = (e: any) => {
        this.setKeyValue(e.target.name, e.target.value);
    }
}

export default withStyles(styles)(NavMenu);
