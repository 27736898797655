import * as React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#A5D7D5',
            contrastText: '#ffffff'
        },
        secondary: {
            main: '#573D90',
            contrastText: '#ffffff'
        },
        background: {
            default: '#ffff'
        }
    },
});

function withRoot(Component: React.ComponentType) {
    function WithRoot(props: object) {
        return (
            <MuiThemeProvider theme={theme}>
                <CssBaseline />
                <Component {...props} />
            </MuiThemeProvider>
        );
    }

    return WithRoot;
}

export default withRoot;