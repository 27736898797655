import React, { Component } from 'react';
import { adalApiFetch } from '../../adalConfig';
import Typography from '@material-ui/core/Typography';

interface IProps {
    setName: (name: string) => void;
}

interface IState {
    name: string;
}

export class UsernameDisplay extends Component<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = { name: 'Unknown' };
    }

    public componentDidMount() {
        // Get Cookies
        const username = this.getCookie("Username");

        // check cache/cookie if username already exists
        if (username !== "") {   // if so, use it
            this.setState({ name: username });

            let finalName = username.replace("Adm ", "");
            console.log(finalName);
            finalName = finalName.split(' ')[0];

            console.log(finalName);
            this.props.setName(finalName);
        }
        else {         // if not, get it and store it in cache.
            adalApiFetch(fetch, 'api/User/getUserName')
                .then((response: Response) => response.text())
                .then((data: string) => {
                    let formattedName = data.split('@')[0];
                    const array = formattedName.split('.');
                    for (let i = 0; i < array.length; i++) {
                        array[i] = array[i].charAt(0).toUpperCase() + array[i].slice(1);
                    }
                    formattedName = array.join(' ');

                    let finalName = formattedName.replace("Adm ", "");
                    console.log(finalName);
                    finalName = finalName.split(' ')[0];

                    console.log(finalName);
                    this.props.setName(finalName);
                    this.props.setName(finalName);

                    const expire = new Date();
                    expire.setTime(expire.getTime() + 1 * 3600 * 1000); // 1 hour

                    this.setState({ name: formattedName });
                    //  sets the Username cookie to the newly fetched username.
                    document.cookie = "Username=" + formattedName + ";expires=" + expire.toUTCString();
                });
        }
    }

    public render() {
        return (
            <Typography variant="subtitle1" align="right" style={{
                textTransform: "capitalize",
                color: "var(--light-text)"
            }
            }> {this.state.name}</Typography>
        );
    }

    private getCookie = (cookieName) => {
        const name = cookieName + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for (let c of ca) {
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
}
