import React, { Component } from 'react';
import { adalApiFetch, authContext } from '../../adalConfig';
import { Button, Grid, Menu, MenuItem, Theme } from '@material-ui/core';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { UsernameDisplay } from './UsernameDisplay';
import { VersionDisplay } from './VersionDisplay';
import { ValidatorForm, SelectValidator } from 'react-material-ui-form-validator';
import { FormControl, Input } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import createStyles from '@material-ui/core/styles/createStyles';

const styles = (theme: Theme) => createStyles({
    dropdown: {
        marginTop: "7px",
        color: 'rgb(87,61,144)',
        borderBottom: '1px solid white',
        transition: theme.transitions.create(['border-color']),
        '& label': {
            color: 'white'
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: 'rgb(87,61,144)'
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottom: '2px solid rgb(87,61,144)'
        },
        '&:focused': {
            borderColor: 'rgb(87,61,144)'
        },
        '& .MuiSelect-icon': {
            fill: 'white'
        }
    },
    input: {
        color: 'white'
    }
});

interface IAccountSectionState {
    anchorEl: any;

    agentNumberSelectList: string[];
    clientSelectList: string[];
    channelSelectList: string[];
}

interface IAccountSectionProps extends WithStyles<typeof styles> {
    adminPrivileges: boolean;
    agentNumber: string;
    channel: string;
    client: string;
    role: string;

    setName: (name: string) => void;
    dropdownChange: (e: any) => void;
}

export class AccountSection extends Component<IAccountSectionProps, IAccountSectionState> {
    constructor(props: IAccountSectionProps) {
        super(props);
        this.state = {
            anchorEl: null,
            agentNumberSelectList: [],
            clientSelectList: [],
            channelSelectList: [],
        };
    }

    public componentDidMount() {
        this.populateAdminDropdowns();
    }

    public render() {
        const classes = this.props.classes;

        let clientDropdown: JSX.Element[] = [];
        if (this.state.clientSelectList !== undefined) {
            clientDropdown = this.state.clientSelectList.map(item =>
                <MenuItem key={item} value={item}>{item}</MenuItem>
            );
        }

        return (
            <div>
                <Button onClick={this.openAccountMenu} aria-haspopup="true" color="inherit">
                    <Grid item >
                        <UsernameDisplay setName={this.props.setName} />
                        <VersionDisplay agentNumber={this.props.agentNumber} />
                    </Grid>
                    <Grid item>
                        <AccountCircle style={{ marginLeft: "10px", marginTop: "10px" }} fontSize="large" />
                    </Grid>
                </Button>
                <Menu style={{ marginTop: "47px" }}
                    anchorEl={this.state.anchorEl}
                    open={Boolean(this.state.anchorEl)}
                    PaperProps={{ style: { marginLeft: "17px", width: "250px", borderRadius: "0px", backgroundColor: "#573D90" } }}
                    onClose={this.closeAccountMenu}
                >
                    <Grid style={{ padding: "5px" }}>
                        <React.Fragment>
                            <ValidatorForm onSubmit={this.applyAdminChanges}>
                                <FormControl fullWidth required>
                                    <SelectValidator
                                        disabled={!(clientDropdown.length > 0)}
                                        name="client"
                                        label="Client"
                                        validators={['required']}
                                        errorMessages={['Required']}
                                        required
                                        fullWidth
                                        value={this.props.client}
                                        onChange={this.clientDropdownChange}
                                        input={<Input required name="client" id="client" />}
                                        InputProps={{ className: classes.input }}
                                        className={classes.dropdown}
                                    >
                                        {clientDropdown}
                                    </SelectValidator>
                                </FormControl>


                                <Button
                                    disabled={
                                        this.props.client === "" || this.props.client === null || !(clientDropdown.length > 0)
                                    }
                                    type="submit"
                                    style={{ marginTop: "7px", borderRadius: "0px", width: "100%" }}
                                    color="secondary"
                                    variant="contained">Apply</Button>
                            </ValidatorForm>
                        </React.Fragment>

                        { /* tslint:disable-next-line jsx-no-lambda */}
                        <Button onClick={() => {
                            const expire = new Date();

                            // remove the cookie
                            document.cookie = "Username=;expires=" + expire.toUTCString();

                            (authContext.logOut())
                        }} style={{ marginTop: "5px", borderRadius: "0px", width: "100%" }} color="secondary" variant="contained">Log Out</Button>
                    </Grid>
                </Menu>
            </div>
        );
    }

    private applyAdminChanges = () => {
        adalApiFetch(fetch, `api/User/UpdateAdminRow?client=${this.props.client}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        })
            .then((response: Response) => response.json())
            .then((data: any) => {
                window.location.reload();
            })
            .catch((error: Error) => {
                console.log('Admin Apply Error: ' + error);
            });
    }

    private clientDropdownChange = (e: any) => {
        this.props.dropdownChange(e);

        // adalApiFetch(fetch, 'api/User/GetChannelsForClient?client=' + e.target.value)
        //     .then((response: Response) => response.json())
        //     .then((data: any) => {
        //         this.setState({ channelSelectList: data });
        //     })
        //     .catch((error: Error) => {
        //         console.log(error);
        //     });
    }

    private openAccountMenu = (event: any) => {
        this.setState({ anchorEl: event.currentTarget });
    }

    private closeAccountMenu = () => {
        this.setState({ anchorEl: null });
    }

    private populateAdminDropdowns = () => {
        adalApiFetch(fetch, 'api/User/GetClients')
            .then((response: Response) => response.json())
            .then((data: any) => {
                this.setState({ clientSelectList: data });
            })
            .catch((error: Error) => {
                console.log(error);
            });

        // adalApiFetch(fetch, 'api/User/GetChannelsForClient?client=' + this.props.client)
        //     .then((response: Response) => response.json())
        //     .then((data: any) => {
        //         this.setState({ channelSelectList: data });
        //     })
        //     .catch((error: Error) => {
        //         console.log(error);
        //     });
        //
        // adalApiFetch(fetch, 'api/User/GetPayrollsForChannel?channel=' + this.props.channel + "&client=" + this.props.client)
        //     .then((response: Response) => response.json())
        //     .then((data: any) => {
        //         this.setState({ agentNumberSelectList: data });
        //     })
        //     .catch((error: Error) => {
        //         console.log(error);
        //     });
    }
}
export default withStyles(styles)(AccountSection);

