import React, { Component } from 'react';
import { adalApiFetch } from '../../adalConfig';
import Typography from '@material-ui/core/Typography';

interface IVersionDisplayState {
    version: string
}

interface IVersionDisplayProps {
    agentNumber: string
}

export class VersionDisplay extends Component<IVersionDisplayProps, IVersionDisplayState> {
    constructor(props: IVersionDisplayProps) {
        super(props);
        this.state = { version: 'test' };
        adalApiFetch(fetch, 'api/Home/GetVersion')
            .then((response: Response) => response.text())
            .then((data: any) => {
                this.setState({ version: data });
            });
    }

    public render() {
        return (
            <div>
                <Typography
                    variant="caption"
                    align="right"
                    style={{ textTransform: "capitalize", color: "var(--light-text)" }}>{"Agent: " + this.props.agentNumber + " - Version: " + this.state.version}
                </Typography>
            </div>
        );
    }
}
