import * as React from 'react';
import NavMenu from './NavMenu';
import withRoot from './withRoot';

export class Layout extends React.Component {
    public render() {
        return (
            <NavMenu>{this.props.children}</NavMenu>
        );
    }
}

export default withRoot(Layout);
