import * as React from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grow from '@material-ui/core/Grow';
import GetAppIcon from '@material-ui/icons/GetApp';
import ClientConfigurationViewModel from '../ViewModels/ClientConfigurationViewModel';
import UserDeviceViewModel from '../ViewModels/UserDeviceViewModel';
import { ReactMic } from 'react-mic';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import * as Bowser from "bowser";
import { adalApiFetch } from '../../adalConfig';


const styles = (theme: Theme) => createStyles({
    root: {
        flexGrow: 1
    },
    pad: {
        padding: theme.spacing(1)
    },
    title: {
        marginRight: theme.spacing(2)
    },
    appBarTitle: {
        marginLeft: theme.spacing(1),
        color: theme.palette.primary.contrastText,
    },
    mic: {
        width: '360px',
        height: '145px',
    },

    input: {},
    dropdown: {}
});

interface INavMenuProps extends WithStyles<typeof styles> {
    openExternalLink: (link: string) => any;
    validateUser: () => any;
    backgroundLogo: () => any;
    setEntered: (index) => any;
    agentNumber: string;
    agentName: string;
    clientConfig: ClientConfigurationViewModel[];
    enteredArray: boolean[];
    group: string[]
}

interface INavMenuState {
    welcomeMessage: string;
    linkMessage: string;
    micTest: boolean;
    micWorking: boolean;
}

export class ByodDisplay extends React.Component<INavMenuProps, INavMenuState> {
    constructor(props: INavMenuProps) {
        super(props);
        this.state = {
            welcomeMessage: '',
            linkMessage: '',
            micTest: false,
            micWorking: false,
        };
    }

    public componentDidMount() {
        this.props.validateUser();
        this.logDevice();
  ;
    }

    public render() {
        const ClientLogo = this.props.backgroundLogo();
        return (
            <React.Fragment>
                {this.props.children}

                <Grow
                    in={true}
                    style={{ transformOrigin: '0 0 0' }}
                    timeout={1000}
                    onEntered={this.props.setEntered(0)}
                >
                    <Typography variant="h3" style={{ marginTop: "5px" }} color="secondary" noWrap>Hello, {this.props.agentName}!</Typography>
                </Grow>
                <br />
                {this.props.clientConfig.map((config) => (
                    <React.Fragment>

                        <Grow
                            in={this.props.enteredArray[0]}
                            style={{ transformOrigin: '0 0 0' }}
                            timeout={1000}
                            onEntered={this.props.setEntered(1)}
                        >
                            <Grid >
                                <Typography variant="h4" style={{ marginTop: "5px" }} color="secondary" noWrap>{config.customText1}</Typography>
                            </Grid>

                        </Grow>
                        <br />
                        <br />
                        <Grow
                            in={this.props.enteredArray[1]}
                            style={{ transformOrigin: '0 0 0' }}
                            timeout={1000}
                            onEntered={this.props.setEntered(2)}
                        >
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography variant="h5" style={{ marginTop: "5px" }} color="secondary" >To get started with ResQ On Any Device we first need to make sure that your headset can work with us to take and receive calls.We need to test your audio and your microphone. </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant="body2" style={{ marginTop: "5px" }} color="secondary" noWrap>Play the video to check you can hear the audio clearly </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant="body2" style={{ marginTop: "5px" }} color="secondary" >When you're ready press the start button and speak into your microphone - you should be able to see your voice as you talk!
 </Typography>
                                </Grid>
                                <Grid item xs={4} />
                                <Grid item xs={4}>
                                    <iframe width="360"
                                        height="200"
                                        src="https://www.youtube.com/embed/IOgEMRElodM"
                                        title="ResQ Celebrates Best Companies 3 Star Achievement"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture">
                                    </iframe>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid container spacing={2}
                                        direction="row"
                                        alignItems="center"
                                    >
                                        <Grid item xs={12}>
                                            <ReactMic
                                                record={this.state.micTest}
                                                className={this.props.classes.mic}
                                                onStop={this.onStop}
                                                onData={this.onData}
                                                timeSlice={1000} 
                                                strokeColor={'#563d8f'}
                                                backgroundColor={'#b9c2c2'} />
                                        </Grid>
                                        <Grid item xs={2} >
                                            <Button size="small" variant='contained' onClick={this.startRecording} type="button">Start</Button>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Button size="small" variant='contained' onClick={this.stopRecording} type="button">Stop</Button>
                                        </Grid>
                                        {this.state.micWorking &&
                                            <Grid item xs={8}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={1} />
                                                        <Grid item >
                                                        <CheckCircleIcon style={{ color: 'green' }} />
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="body2" color="secondary" >Microphone working</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grow>
                        <br />
                        <br />
                        <Grow
                            in={this.props.enteredArray[2]}
                            style={{ transformOrigin: '0 0 0' }}
                            timeout={1000}
                            onEntered={this.props.setEntered(3)}
                        >
                            <div>
                                {/*<Typography variant="h5" style={{ marginTop: "5px" }} color="secondary" noWrap>{config.customText2}</Typography>*/}
                                <Typography variant="h5" style={{ marginTop: "5px" }} color="secondary" >If both of those tests were successful then you're ready to start working on your device! To get started go on to the virtual desktop where you'll be able to find everything you need to start working - we also have some helpful support documents you can download, which should answer any questions you might have on how to get started.</Typography>
                            </div>
                        </Grow>
                        <br />
                        <Grow
                            in={this.props.enteredArray[3]}
                            style={{ transformOrigin: '0 0 0' }}
                            timeout={3000}
                            onEntered={this.props.setEntered(4)}
                        >
                            <div>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <Button disabled={!this.state.micWorking} onClick={this.props.openExternalLink(config.documentLink)} variant="contained" color="secondary">Support Documents <GetAppIcon /></Button>
                                    </Grid>
                                    <Grid item>
                                        <Button disabled={!this.state.micWorking} onClick={this.props.openExternalLink(config.softwareLink)} variant="contained" color="secondary">{config.group === 'Virtual Desktop - DR' ? 'Virtual Desktop' : 'Software Installer'}<GetAppIcon /></Button>
                                    </Grid>
                                    <Grid item xs={8}> </Grid>
                                </Grid>
                            </div>
                        </Grow>

                        {ClientLogo}
                        < br />
                        <br />
                        <br />
                    </React.Fragment >

                ))}
                <br />
                <br />
                <Grow
                    in={this.props.enteredArray[4]}
                    style={{ transformOrigin: '0 0 0' }}
                    timeout={1000}
                >
                    <Typography variant="h6" color="secondary" style={{ margin: '10px' }}>
                        For further support, please contact us at <a style={{ color: '#573D90' }} href="mailto:ITServicesTeam@resqcs.co.uk">ITServicesTeam@resqcs.co.uk</a> or on 01482 481 200
                    </Typography>
                </Grow>

            </React.Fragment >

        )
    }
    private startRecording = () => {
        this.setState({ micTest: true });
    }
    private stopRecording = () => {
        this.setState({ micTest: false });
    }
    private onData = (recordedBlob) =>{
        this.setState({ micWorking: true });

        console.log('chunk of real-time data is: ', recordedBlob);
    }

    private onStop = (recordedBlob) => {
        console.log('recordedBlob is: ', recordedBlob);
    }

    private logDevice = () => {

        let device: UserDeviceViewModel = new UserDeviceViewModel();
        device.browserName = Bowser.getParser(window.navigator.userAgent).getBrowserName();
        device.browserVersion = Bowser.getParser(window.navigator.userAgent).getBrowserVersion();
        device.osName = Bowser.getParser(window.navigator.userAgent).getOSName();
        device.osVersion = Bowser.getParser(window.navigator.userAgent).getOSVersion();
        device.platformType = Bowser.getParser(window.navigator.userAgent).getPlatformType();
        device.group = this.props.group[0];
        adalApiFetch(fetch, `api/User/UpdateUserDevice`, {
            method: 'POST',
            body: JSON.stringify(device), // body data type must match "Content-Type" header
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        })
            .then(Response => Response.json())
            .then((data: any) => {
            })
            .catch((Error: Error) => {
            })
    }
}

export default withStyles(styles)(ByodDisplay);
